import pluralize from 'pluralize'
import format from 'date-fns/format'
import isEqual from 'date-fns/isEqual'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import { now, today, dateFromString, dateTimeFromString, toDateString, daySpan, toTimeString } from 'utils/dateUtils'

export const getAllUnits = compositeData => compositeData?.flatMap(site => site.categories)?.flatMap(category => category.units) ?? []

export const getAvailableUnits = compositeData => getAllUnits(compositeData).filter(unit => !unit.inCart)

export const getReservedUnits = compositeData => getAllUnits(compositeData).filter(unit => unit.inCart)

export const getAvailableUnitIds = compositeData => getAvailableUnits(compositeData).map(unit => unit.id)

export const getReservedUnitIds = compositeData => getReservedUnits(compositeData).map(unit => unit.id)

export const findDataForUnitId = (compositeData, unitId) =>
  compositeData.find(data =>
    data.categories.find(category =>
      category.units.find(unit =>
        unit.id === unitId
      )
    )
  )

export const findDataForCategoryId = (compositeData, categoryId) =>
  compositeData.find(data => {
    if (data.detail.staylist_id) {
      return data.detail.staylist_id === categoryId
    }
    else if (data.detail.staylist_unit_category_ids) {
      return data.detail.staylist_unit_category_ids.map(catId => catId.id).includes(categoryId)
    }
    return undefined
  })

export const findUnitById = (compositeData, unitId) => {
  for (const data of compositeData) {
    for (const category of data.categories) {
      for (const unit of category.units) {
        if (unit.id === unitId) {
          return {
            detail: data.detail,
            type: data.type,
            category,
            unit
          }
        }
      }
    }
  }
}

export const setUnitInCart = (compositeData, unitId, inCart) => {
  for (const data of compositeData) {
    for (const category of data.categories) {
      for (const unit of category.units) {
        if (unit.id === unitId) {
          unit.inCart = inCart
          return
        }
      }
    }
  }
}

export const unitIsPetFriendly = unit => unit.amenities.find(amenity => +amenity.id === 14 || +amenity.id === 114)
export const unitIsADAAccessible = unit => unit.amenities.find(amenity => +amenity.id === 15 || +amenity.id === 155)

/** Reservation Utilities **/

export const reservationDateSpanString = (reservation, showWeekdays = true) => {
  const showCheckinYear = reservation.checkin.slice(0, 4) !== reservation.checkout.slice(0, 4)
  return `${toDateString(reservation.checkin, { showWeekday: showWeekdays, showYear: showCheckinYear })} – ${toDateString(reservation.checkout, { showWeekday: showWeekdays, showYear: true })}`
}

export const reservationTimeslotDateString = reservation => format(dateFromString(reservation.checkin), 'EEE, MMM dd, yyyy')

export const reservationTimeslotTimeString = reservation =>
  `${toTimeString(reservation.rates[0].start_time, { showMinutes: true })} - ${toTimeString(reservation.rates[0].end_time, { showMinutes: true })}`

export const reservationTimeslotDateTimeString = reservation =>
  `${reservationTimeslotDateString(reservation)}, ${reservationTimeslotTimeString(reservation)}`

export const reservationGuestString = (reservation, showPets = true) =>
  `${pluralize('adult', +reservation.adults, true)}${+reservation.children === 0 ? '' : `, ${pluralize('child', +reservation.children, true)}`}${+reservation.pets !== 0 && showPets ? ', up to 2 dogs' : ''}`

export const reservationNightSpan = reservation => daySpan(reservation.checkin, reservation.checkout)
export const reservationNightSpanString = reservation => pluralize('night', reservationNightSpan(reservation), true)

export const reservationRateString = reservation => {
  const rateArray = reservation.rates.map(rate => +rate.rate)
  const lowestRate = Math.min(...rateArray)
  const highestRate = Math.max(...rateArray)
  const averageRate = +reservation.rates_total / reservation.rates.length
  return `$${averageRate.toFixed(0)} / day${lowestRate === highestRate ? '' : ` (avg), low: $${lowestRate}, high: $${highestRate}`}`
}

export const reservationBookedDays = reservation => {
  const startDay = dateFromString(reservation.checkin)
  if (reservation.checkin === reservation.checkout) {
    return [{ date: startDay, status: 'full' }]
  }
  const endDay = dateFromString(reservation.checkout)
  return eachDayOfInterval({ start: startDay, end: endDay }).map(date => {
    const status = isEqual(date, startDay) ? 'start' : isEqual(date, endDay) ? 'end' : 'full'
    return { date, status }
  })
}

/** Folio Utilities **/

export const folioAddonTypes = ['kit', 'alaCarte', 'snack']

export const folioIsPending = folio => !!folio?.reservations && folio?.status === 'pending' && !!folio?.expires_on

export const folioAddons = folio => folio?.reservations?.filter(reservation => folioAddonTypes.includes(reservation.type)) ?? []
export const folioHasAddons = folio => folioAddons(folio).length > 0

export const folioCampsites = folio => folio?.reservations?.filter(reservation => reservation.type === 'site') ?? []
export const folioHasCampsites = folio => folioCampsites(folio).length > 0

export const folioTimeslots = folio => folio?.reservations?.filter(reservation => reservation.type === 'ofuro').sort((a, b) => a.checkin.localeCompare(b.checkin)) ?? []
export const folioHasTimeslots = folio => folioTimeslots(folio).length > 0

export const folioFees = folio => folio?.reservations?.reduce((total, cur) => total.concat(cur.charges).concat(cur.taxes), [])
export const folioHasFees = folio => folioFees(folio).length > 0

export const folioGrandTotal = folio => folio?.reservations?.reduce((total, cur) => total + +cur.grand_total, 0) ?? 0

export const folioStartDate = folio => folio?.reservations?.map(reservation => reservation.checkin).sort((a, b) => a.localeCompare(b)).shift()
export const folioEndDate = folio => folio?.reservations?.map(reservation => reservation.checkout).sort((a, b) => a.localeCompare(b)).pop()

export const folioIsUpcoming = folio => dateFromString(folioStartDate(folio)) > today()
export const upcomingFolios = folios => folios.filter(folioIsUpcoming)
export const hasUpcomingFolios = folios => upcomingFolios(folios).length > 0

export const folioIsPast = folio => dateFromString(folioEndDate(folio)) < today()
export const pastFolios = folios => folios.filter(folioIsPast)
export const hasPastFolios = folios => pastFolios(folios).length > 0

export const folioIsCurrent = folio => dateFromString(folioStartDate(folio)) <= today() && dateFromString(folioEndDate(folio)) >= today()
export const currentFolios = folios => folios.filter(folioIsCurrent)
export const hasCurrentFolios = folios => currentFolios(folios).length > 0

export const folioBookingParams = folio => {
  if (!folio?.reservations) { return {} }
  return {
    checkin: folio?.reservations?.map(reservation => reservation.checkin).sort((a, b) => a.localeCompare(b))[0],
    checkout: folio?.reservations?.map(reservation => reservation.checkout).sort((a, b) => b.localeCompare(a))[0],
    adults: folio?.reservations?.map(reservation => reservation.adults).reduce((total, cur) => Math.max(total, +cur), 1),
    children: folio?.reservations?.map(reservation => reservation.children).reduce((total, cur) => Math.max(total, +cur), 0),
    pets: folio?.reservations?.map(reservation => reservation.pets).reduce((total, cur) => Math.max(total, +cur), 0),
    adaAccessible: false,
    folio_id: folio?.folio_id
  }
}

/** Timeslot Utilities **/

export const timeslotIsUpcoming = timeslot => {
  if (dateFromString(timeslot.checkin) > today()) {
    return true
  }
  else if (dateFromString(timeslot.checkin) === today()) {
    return dateTimeFromString(timeslot.checkin, timeslot.rates[0].end_time) > now()
  }
  return false
}
