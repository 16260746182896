import differenceInDays from 'date-fns/differenceInDays'
import isValid from 'date-fns/isValid'
import { utcToZonedTime } from 'date-fns-tz'
import startOfDay from 'date-fns/startOfDay'

export const now = () => new Date()

export const today = () => startOfDay(utcToZonedTime(new Date()))

export const dateFromString = dateString => {
  if (!dateString) { return }
  const newDate = new Date(dateString)
  if (!isValid(newDate)) { return }
  return startOfDay(utcToZonedTime(newDate))
}

export const dateTimeFromString = (dateString, timeString) => {
  const result = dateFromString(dateString)
  if (!result) { return }
  const timeParts = timeString.split(':')
  result.setHours(+timeParts[0], +timeParts[1], +timeParts[2])
  return result
}

export const daySpan = (startDateString, endDateString) => {
  const startDate = dateFromString(startDateString)
  const endDate = dateFromString(endDateString)
  if (!startDate || !endDate) {
    return 0
  }
  return differenceInDays(endDate, startDate)
}

export const toDateString = (date, options) => {
  const compiledOptions = {
    day: 'numeric',
    month: options?.longMonth ? 'long' : 'short',
    timeZone: 'UTC'
  }
  if (options?.showWeekday) {
    compiledOptions.weekday = 'short'
  }
  if (options?.showYear) {
    compiledOptions.year = 'numeric'
  }
  return dateFromString(date).toLocaleString('en-US', compiledOptions)
}

export const toTimeString = (timeString, options) => {
  const splitTime = timeString.split(':')
  const hours = +splitTime[0]
  const minutes = splitTime[1]
  const formattedHours = hours % 12
  return `${formattedHours === 0 ? 12 : formattedHours}${options?.showMinutes ? `:${minutes}` : ''}${hours >= 12 ? 'pm' : 'am'}`
}

export const timeIsMorning = timeString => +timeString.split(':')[0] < 12
export const timeIsAfternoon = timeString => +timeString.split(':')[0] >= 12 && +timeString.split(':')[0] < 18
export const timeIsEvening = timeString => +timeString.split(':')[0] >= 18
